import { API } from "@/constants-app";
import {
  QuickSearchPlayerModel,
  SearchPlayersModel,
} from "@/models/search-player.ts";
import { QuicKSearchClanModel } from "@/models/search-clan.ts";
import type { QuickSearchPlayer } from "@/models/search-player.ts";
import type { SearchClan } from "@/models/search-clan.ts";
import type { SearchPlayers } from "@/models/search-player.ts";

export async function searchPlayer(
  searchString: string
): Promise<SearchPlayers | undefined> {
  try {
    const response = await fetch(`${API}/search/p/player`, {
      method: "POST",
      body: JSON.stringify({
        q: searchString,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    const parsed = SearchPlayersModel.parse(data);
    console.log(parsed);
    return parsed;
  } catch (e) {
    console.error(e);
  }
}

export async function quickSearchPlayer(
  playerTag: string
): Promise<QuickSearchPlayer | undefined> {
  try {
    if (!playerTag) throw new Error("No player tag");
    const response = await fetch(`${API}/quicksearch/player/%23${playerTag}`, {
      method: "GET",
    });
    if (!response.ok)
      throw new Error("Failed to fetch quicksearch player data");
    const data = await response.json();
    const parsed = QuickSearchPlayerModel.parse(data);
    return parsed;
  } catch (e) {
    console.error(e);
  }
}

export async function quickSearchClan(
  clanTag: string
): Promise<SearchClan | undefined> {
  try {
    if (!clanTag) throw new Error("No quick search clan");
    const response = await fetch(`${API}/quicksearch/clan/%23${clanTag}`, {
      method: "GET",
    });
    if (!response.ok) throw new Error("Failed to fetch quicksearch clan data");
    const data = await response.json();
    const parsed = QuicKSearchClanModel.parse(data);
    return parsed;
  } catch (e) {
    console.error(e);
  }
}

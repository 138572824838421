import { LS_PLAYER_SEARCH } from "@/constants-app.ts";
import { SearchPlayersLSModel } from "@/models/search-player.ts";
import type { SearchPlayerLS, SearchPlayers } from "@/models/search-player.ts";
import _ from "lodash";

export function getPlayerSearch(): SearchPlayerLS {
  try {
    const string = localStorage.getItem(LS_PLAYER_SEARCH);
    if (!string) return {}; // Empty local storage
    return SearchPlayersLSModel.parse(JSON.parse(string));
  } catch (e) {
    console.error(
      "Failed to populate player search history via local storage",
      e
    );
    return {}; // Empty local storage
  }
}

export function addPlayerToSearch(player: SearchPlayers[0]): void {
  try {
    const playersLS = getPlayerSearch();
    if (playersLS[player.tag]) return;
    playersLS[player.tag] = player;
    _addPlayersToSearch(playersLS);
  } catch (e) {
    console.error(e);
  }
}

export function addPlayersToSearch(players: SearchPlayers): void {
  try {
    const playersLS = getPlayerSearch();
    let isAdded = false;
    for (const player of players) {
      if (playersLS[player.tag]) continue;
      playersLS[player.tag] = player;
      if (isAdded) continue;
      isAdded = true;
    }
    if (!isAdded) return;
    _addPlayersToSearch(playersLS);
  } catch (e) {
    console.error(e);
  }
}

export function clearPlayerFromSearch(tag: string): void {
  try {
    const players = getPlayerSearch();
    if (!players[tag]) return;
    delete players[tag];
    _addPlayersToSearch(players);
  } catch (e) {
    console.error(e);
  }
}

function _addPlayersToSearch(players: SearchPlayerLS): void {
  localStorage.setItem(
    LS_PLAYER_SEARCH,
    JSON.stringify(SearchPlayersLSModel.parse(players))
  );
}

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { SearchClan } from "@/models/search-clan.ts";
import type { Cards } from "@/models/static.ts";
import type { SearchPlayers } from "@/models/search-player.ts";

const searchSlice = createSlice({
  name: "search",
  initialState: {
    isPlayerFetching: false as boolean,
    players: [] as SearchPlayers,
    isClanFetching: false as boolean,
    clans: [] as Array<SearchClan>,
    cards: [] as Cards["items"] & { elapsed?: number },
  },
  reducers: {
    addPlayer(state, action: PayloadAction<SearchPlayers[0]>) {
      if (state.players.find((i) => i.tag === action.payload.tag)) return;
      const next = [...state.players];
      next.push(action.payload);
      next.sort((a, b) => b.last_updated - a.last_updated);
      state.players = next;
    },
    addPlayers(state, action: PayloadAction<SearchPlayers>) {
      if (!action.payload.length) return;
      const diff = action.payload.reduce((acc, cur) => {
        if (state.players.some((j) => j.tag === cur.tag)) return acc;
        acc.push(cur);
        return acc;
      }, [] as SearchPlayers);
      if (!diff.length) return;
      const next = state.players.concat(diff);
      next.sort((a, b) => b.last_updated - a.last_updated);
      state.players = next;
    },
    addClan(state, action: PayloadAction<SearchClan>) {
      if (state.clans.find((i) => i.tag === action.payload.tag)) return;
      const next = [...state.clans];
      next.push(action.payload);
      next.sort((a, b) => b.lastUpdated - a.lastUpdated);
      state.clans = next;
    },
    setCards(
      state,
      action: PayloadAction<Cards["items"] & { elapsed?: number }>
    ) {
      state.cards = action.payload;
    },
    setPlayerFetching(state, action: PayloadAction<boolean>) {
      state.isPlayerFetching = action.payload;
    },
    setClanFetching(state, action: PayloadAction<boolean>) {
      state.isClanFetching = action.payload;
    },
    clearAll(state) {
      state.players = [];
      state.clans = [];
      state.cards = [];
    },
  },
});

export const {
  addPlayer,
  addPlayers,
  addClan,
  setPlayerFetching,
  setClanFetching,
  setCards,
  clearAll,
} = searchSlice.actions;
export default searchSlice.reducer;

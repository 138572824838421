"use client";

import SearchResults from "@/components/SearchResults.tsx";
import SearchInput from "@/components/SearchInput.tsx";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/utils/hooks.ts";
import {
  addClan,
  addPlayer,
  addPlayers,
  setClanFetching,
  setPlayerFetching,
  setCards,
  clearAll,
} from "@/utils/reducer-search.ts";
import { quickSearchClan, searchPlayer } from "@/fetches/search.ts";
import * as RTStorage from "@/utils/localstorage.ts";
import { getSearchPlayers } from "@/utils/search.ts";
import _ from "lodash";

const SEARCH_RESULT_OFFSET = 4;

export default function Search({ isDark = false }: { isDark?: boolean }) {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null); // Container div element reference
  const [offset, setOffset] = useState(0);
  const [isEnabled, setEnabled] = useState(false);
  const cards = useAppSelector((state) => state.static.cards);
  const onLoad = useCallback((rect: DOMRect) => {
    setOffset(rect.height + SEARCH_RESULT_OFFSET);
  }, []);
  const handleEnableResults = useCallback(() => {
    setEnabled(true);
    // If enabled with no results, populate search results with players and clans
    const searchPlayers = getSearchPlayers();
    if (Array.isArray(searchPlayers) && !searchPlayers.length) {
      const playersLS = RTStorage.getPlayerSearch();
      if (_.isObject(playersLS)) {
        const list = Object.values(playersLS);
        const set = new Set<number>();
        const players = [];
        let i = 0;
        for (const k in playersLS) {
          if (i >= 10) break;
          i += 1;
          const num = createRandomNumber(list.length - 1, set);
          if (!set.has(num)) players.push(list[num]);
          set.add(num);
        }
        set.clear();
        dispatch(addPlayers(players));
      }
    }
  }, []);
  const onFocusOut = useCallback((event?: FocusEvent) => {
    setEnabled(false);
  }, []);
  const onSearch = useCallback(
    (searchString: string) => {
      setEnabled(!!searchString);
      dispatch(clearAll());
      // Data fetching based off of the search string
      if (!searchString) return;
      const ssLowerCase = searchString.toLowerCase();
      Promise.all([
        // Player search
        (async () => {
          dispatch(setPlayerFetching(true));

          // Search within localstorage for existing player tags or player names, then add to search results
          const playersLS = RTStorage.getPlayerSearch();
          if (_.isObject(playersLS)) {
            for (const k in playersLS) {
              const i = playersLS[k];
              if (
                !i.name.toLowerCase().includes(ssLowerCase) &&
                !i.tag.toLowerCase().includes(ssLowerCase)
              )
                continue;
              dispatch(addPlayer(i));
            }
          }

          // Search Royale Tactics BE
          const players = await searchPlayer(searchString);
          if (Array.isArray(players)) {
            dispatch(addPlayers(players));
            // Add to localstorage
            RTStorage.addPlayersToSearch(players);
          }

          dispatch(setPlayerFetching(false));
        })(),
        // Clan search
        (async () => {
          dispatch(setClanFetching(true));
          const clan = await quickSearchClan(searchString);
          if (clan) dispatch(addClan(clan));
          dispatch(setClanFetching(false));
        })(),
        // Card search
        (async () => {
          if (!cards) return;
          const results = [];
          const start = performance.now();
          for (const k in cards) {
            const card = cards[k];
            if (!card.name.toLowerCase().includes(searchString.toLowerCase()))
              continue;
            results.push(card);
          }
          Object.defineProperty(results, "elapsed", {
            value: performance.now() - start,
            writable: true,
            enumerable: true,
            configurable: true,
          });
          dispatch(setCards(results));
        })(),
      ]);
    },
    [cards]
  );
  // Custom focus out listener for Royale Tactics use cases
  useEffect(() => {
    const onFocusOutListener = focusOut(ref.current as Element, onFocusOut);
    if (isEnabled) globalThis.addEventListener("click", onFocusOutListener);
    return () => {
      globalThis.removeEventListener("click", onFocusOutListener);
    };
  }, [isEnabled]);
  return (
    <div className="relative w-full drop-shadow-lg" ref={ref}>
      <SearchInput
        onLoad={onLoad}
        onSearch={onSearch}
        onFocus={handleEnableResults}
        isDark={isDark}
      />
      {isEnabled && (
        <div
          id="search-results"
          className="w-full absolute"
          style={{ zIndex: 100, top: `${offset}px` }}
        >
          <SearchResults />
        </div>
      )}
    </div>
  );
}

function isWithinScope(
  e: Element,
  ref: Element | null,
  depth: number = 0
): boolean {
  if (!e || !ref || depth > 10) return false;
  if (e === ref) return true;
  return isWithinScope(e.parentElement as Element, ref, depth + 1);
}

const focusOut =
  (refTarget: Element, callback: () => void) => (event: MouseEvent) => {
    event.preventDefault();
    if (isWithinScope(event.target as Element, refTarget)) return;
    callback();
  };

function createRandomNumber(length: number, set: Set<number>): number {
  if (length === 0) return 0;
  const num = _.random(length, false);
  if (set.has(num)) return createRandomNumber(length, set);
  return num;
}

import type { Unsubscribe } from "firebase/auth";
import type { AppStore } from "@/utils/store.ts";
import type { Analytics } from "firebase/analytics";
import type { Client as SentryClient } from "@sentry/types";
import { VERSION_DEFAULT } from "@/constants-game.ts";
import type { Event } from "@/utils/pompompurin.ts";
import { trackEvent } from "@/utils/pompompurin.ts";

export const refs = {
  authUnsubscribe: null,
  reduxStore: null,
  analytics: null,
  sentry: undefined,
  version: VERSION_DEFAULT,
  pom: trackEvent,
} as {
  authUnsubscribe: Unsubscribe | null;
  reduxStore: AppStore | null;
  analytics: Analytics | null;
  sentry: SentryClient | undefined;
  version: string;
  pom: (name: string, data?: Event) => Promise<void>;
};

// @ts-ignore
globalThis.__ROYALE__ = {
  refs,
};

// Define the type for an event that can either be a Promise or a function returning a Promise
type Callback = Promise<void> | (() => Promise<void>);

export const Queue = () => {
  const queue: Array<{
    event: Callback;
    resolve: () => void;
    reject: (error: any) => void;
  }> = [];
  let processing = false;

  // Function to add a new event to the queue (can be a Promise or a function returning a Promise)
  const enqueue = (event: Callback): Promise<void> => {
    return new Promise((resolve, reject) => {
      queue.push({ event, resolve, reject });
      processQueue(); // Start processing when a new event is added
    });
  };

  // Function to process events in the queue one by one
  const processQueue = async (): Promise<void> => {
    if (processing || queue.length === 0) {
      return; // If already processing or no events, exit
    }

    processing = true; // Mark as processing

    while (queue.length > 0) {
      const { event, resolve } = queue.shift()!; // Get the first event in the queue
      try {
        // Check if the event is a function, if so, call it, otherwise just await the promise
        if (typeof event === "function") {
          await event(); // Call and await the function if it returns a Promise
        } else {
          await event; // Await the Promise directly
        }
        resolve(); // Resolve the promise when done
      } catch (error) {
        console.error("Error processing event:", error); // Log the error
        resolve(); // Resolve the promise even on failure, so the queue continues
      }
    }

    processing = false; // Reset processing flag when done
  };

  return { enqueue };
};

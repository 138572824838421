import { API } from "@/constants-app";
import type { Clan, ClanMembers as ClanMembersType } from "@/models/clan.ts";
import { ClanMembers, ClanModel } from "@/models/clan.ts";
import { captureException } from "@sentry/react";

export default async function getClan(
  clanTag?: string
): Promise<Clan | undefined> {
  try {
    if (!clanTag) return;
    const response = await fetch(
      `${API}/search/clan/${encodeURIComponent(clanTag)}`,
      {
        method: "GET",
      }
    );
    if (!response.ok) throw new Error("Failed to fetch clan data");
    const data = await response.json();
    const parsed = ClanModel.parse(data);
    return parsed;
  } catch (e) {
    captureException(e);
    console.error(e);
  }
}

export async function getClanMembers(
  clanTag?: string
): Promise<ClanMembersType | undefined> {
  try {
    if (!clanTag) return;
    const response = await fetch(
      `${API}/clan/${encodeURIComponent(clanTag)}/members`,
      {
        method: "GET",
      }
    );
    if (!response.ok) throw new Error("Failed to fetch clan data");
    const data = await response.json();
    const parsed = ClanMembers.parse(data);
    return parsed;
  } catch (e) {
    captureException(e);
    console.error(e);
  }
}

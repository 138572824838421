export const EVENTS = {
  PAGE_VIEW: "PAGE_VIEW",
  SEARCH_RESULTS: "SEARCH_RESULTS",
  SEARCH_RESULT_CLICK: "SEARCH_RESULT_CLICK",
  ENGAGEMENT: "ENGAGEMENT",
  USER_CREATE: "USER_CREATE",
  USER_LOGIN: "USER_LOGIN",
  USER_LOGIN_ANONYMOUS: "USER_LOGIN_ANONYMOUS",
  USER_LOGOUT: "USER_LOGOUT",
  USER_RESET_PASSWORD: "USER_RESET_PASSWORD",
  GOOGLE_AUTH: "GOOGLE_AUTH",
};

import { STATIC_URL } from "@/constants-game.ts";
import type { Player } from "@/models/player.ts";
import type { Cards } from "@/models/static.ts";
import { Tooltip } from "@shopify/polaris";
import Image from "next/image";
import { memo } from "react";

const sizes = {
  small: {
    width: 39,
    height: 57,
  },
  medium: {
    width: 45,
    height: 66,
  },
  large: {
    width: 57,
    height: 84,
  },
};
const STAR_SIZE = {
  WIDTH: 11,
  HEIGHT: 13,
};

type PlayerCard = Player["battlelog"][0]["team"][0]["cards"][0];
type StaticCard = Cards["items"][0];

export default memo(function CardImage({
  card,
  isLeader = false,
}: {
  card: PlayerCard | StaticCard;
  isLeader?: boolean;
}) {
  // There are 2 types of card arguments, this differentiates them
  const isPlayerCard = "level" in card;

  const tooltips = [card.name];
  if (isPlayerCard && card.maxLevel && card.level < card.maxLevel)
    tooltips.push(`Level: ${card.level}/${card.maxLevel}`);
  if (isPlayerCard && card.level && card.evolutionLevel)
    tooltips.push(
      `Evolution Level: ${card.evolutionLevel}/${card.maxEvolutionLevel}`
    );
  return (
    <div className="relative">
      <Tooltip persistOnClick content={tooltips.join(", ")}>
        <div>
          {!!card.elixirCost && (
            <div className="elixer relative">
              <div className="font-bold text-white absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                {card.elixirCost}
              </div>
              <Image
                src={`${STATIC_URL}/game/elixir.webp`}
                alt="Elixir"
                width={20}
                height={20}
                loading="lazy"
              />
            </div>
          )}
          <Image
            src={card.iconUrls?.evolutionMedium ?? card.iconUrls?.medium ?? ""}
            alt={card.name ?? card.id.toString()}
            width={isLeader ? sizes.large.width : sizes.medium.width}
            height={isLeader ? sizes.large.height : sizes.medium.height}
            loading="lazy"
            className="shrink-0 max-w-fit"
          />
        </div>
      </Tooltip>
      {Boolean(
        isPlayerCard &&
          card.maxLevel &&
          card.starLevel &&
          card.level > card.maxLevel
      ) && (
        <div className="absolute bottom-0 left-1/2 -translate-x-1/2">
          <div
            className="grid items-center"
            style={{
              gridTemplateColumns: `repeat(${
                (card as PlayerCard).starLevel
              }, minmax(${STAR_SIZE.WIDTH}px, 1fr))`,
            }}
          >
            {new Array((card as PlayerCard).starLevel)
              .fill(undefined)
              .map((_, idx) => (
                <Image
                  key={idx.toString()}
                  alt={`Star Level ${idx + 1}`}
                  src="/star.webp"
                  width={STAR_SIZE.WIDTH}
                  height={STAR_SIZE.HEIGHT}
                  className="shrink-0"
                  loading="lazy"
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
});

import { captureException } from "@sentry/react";
import { ZodTypeAny, infer as ZodInfer, ZodError } from "zod";

// Define the fetcher function
const fetcher =
  <T extends ZodTypeAny>(model: T) =>
  async (url: string): Promise<ZodInfer<T>> => {
    try {
      const response = await fetch(url);
      if (!response.ok)
        throw new Error(
          `useSWR Fetcher received bad response\n${response.statusText}`
        );
      const data = await response.json();
      return model.parse(data); // Will validate the data and return the parsed result
    } catch (e) {
      captureException(e?.toString?.());
      console.error(e);
    }
  };

export default fetcher;

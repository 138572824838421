import z from "zod";

const Member = z.object({
  donationsReceived: z.number(),
  clanRank: z.number(),
  previousClanRank: z.number(),
  role: z.string(),
  lastSeen: z.number(),
  donations: z.number(),
  name: z.string(),
  expLevel: z.number(),
  tag: z.string(),
  arena: z.object({
    name: z.string(),
    id: z.number(),
  }),
  trophies: z.number(),
  clanChestPoints: z.number(),
});

const ItemRiverRace = z.object({
  sectionIndex: z.number(),
  createdDate: z.string(),
  seasonId: z.number(),
  standings: z.array(
    z.object({
      trophyChange: z.number(),
      clan: z.object({
        finishTime: z.string(),
        badgeId: z.number(),
        name: z.string(),
        repairPoints: z.number(),
        tag: z.string(),
        clanScore: z.number(),
        fame: z.number(),
        participants: z.array(
          z.object({
            decksUsedToday: z.number(),
            name: z.string(),
            repairPoints: z.number(),
            boatAttacks: z.number(),
            decksUsed: z.number(),
            tag: z.string(),
            fame: z.number(),
          })
        ),
        periodPoints: z.number(),
      }),
      rank: z.number(),
    })
  ),
});

const ItemMember = z.object({
  donationsReceived: z.number(),
  clanRank: z.number(),
  previousClanRank: z.number(),
  role: z.string(),
  lastSeen: z.string(),
  donations: z.number(),
  name: z.string(),
  expLevel: z.number(),
  tag: z.string(),
  arena: z.object({
    name: z.string(),
    id: z.number(),
  }),
  trophies: z.number(),
  clanChestPoints: z.number(),
});

export const ClanMembers = z.object({
  items: z.array(ItemMember),
});

export const ClanModel = z.object({
  riverracelog: z.object({
    items: z.array(ItemRiverRace),
  }),
  warlog: z.nullable(z.unknown()),
  members: ClanMembers,
  clan: z.object({
    clanChestLevel: z.number(),
    badgeId: z.number(),
    requiredTrophies: z.number(),
    description: z.string(),
    type: z.string(),
    donationsPerWeek: z.number(),
    memberList: z.array(Member),
    members: z.number(),
    name: z.string(),
    clanWarTrophies: z.number(),
    location: z.object({
      isCountry: z.boolean(),
      countryCode: z.string().optional(),
      name: z.string(),
      id: z.number(),
    }),
    tag: z.string(),
    clanScore: z.number(),
    clanChestMaxLevel: z.number(),
    clanChestStatus: z.string(),
  }),
  lastUpdated: z.number(),
});

export type Clan = z.infer<typeof ClanModel>;
export type ClanMembers = z.infer<typeof ClanMembers>;

import { API } from "@/constants-app.ts";
import { getClanMembers } from "@/fetches/clan.ts";
import { LeaderboardsClansModel } from "@/models/leaderboards.ts";
import type { LeaderboardsClans } from "@/models/leaderboards.ts";
import fetcher from "@/utils/fetcher.ts";
import { useLocation } from "@/utils/use-location.ts";
import { useEffect } from "react";
import useSWR from "swr";
import * as RTStorage from "@/utils/localstorage.ts";
import { captureException } from "@sentry/react";

export default function useTopClans() {
  const location = useLocation();
  const { data, error, isLoading } = useSWR<LeaderboardsClans>(
    location?.id ? API + "/leaderboards/clans/" + location.id : null,
    fetcher(LeaderboardsClansModel)
  );
  useEffect(() => {
    if (error)
      captureException({
        message: "useTopClans failed to fetch using swr",
        level: "error", // 'info', 'warning', 'error', etc.
      });
  }, [error]);
  useEffect(() => {
    if (Array.isArray(data?.topClans) && data.topClans.length) {
      (async () => {
        const members = [];
        for (let i = 0; i < 5; i += 1) {
          if (members.length >= 20) break;
          const topClan = data.topClans[i];
          if (!topClan) continue;
          const clanMembers = await getClanMembers(topClan.tag);
          if (!Array.isArray(clanMembers?.items) || !clanMembers.items.length)
            continue;
          for (const m of clanMembers.items) {
            if (members.length >= 20) break;
            members.push({
              last_updated: Date.now(),
              name: m.name,
              tag: m.tag,
            });
          }
        }
        RTStorage.addPlayersToSearch(members);
      })();
    }
  }, [data?.topClans]);
  return {
    topClans: data,
    error,
    isLoading,
  };
}

import z from "zod";

const EventModel = z.object({
  type: z.string(),
  startTime: z.number(),
  endTime: z.number(),
  challenges: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      description: z.string(),
      winMode: z.string(),
      casual: z.boolean(),
      maxLosses: z.number(),
      gameMode: z.object({
        id: z.number(),
        name: z.string(),
      }),
      prizes: z.array(
        z.object({
          rarity: z
            .union([
              z.literal("common"),
              z.literal("rare"),
              z.literal("epic"),
              z.literal("legendary"),
              z.literal("champion"),
            ])
            .optional(),
          type: z.string().nullable(),
          amount: z.number().optional(),
          consumableName: z.string().optional(),
          wins: z.number().optional(),
        })
      ),
      maxWins: z.number(),
      iconUrl: z.string(),
    })
  ),
});

export const EventsModel = z.object({
  date: z.number(),
  events: z.array(EventModel),
});

export type Events = z.infer<typeof EventsModel>;

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/utils/hooks.ts";

const RegExpCountryCode = /\w+$/;

export function useLocalLocation() {
  const {
    i18n: { language },
  } = useTranslation();
  const countryCode = useMemo<string | undefined>(
    () => language?.match(RegExpCountryCode)?.[0],
    [language]
  );
  const locations = useAppSelector((state) => state.static.locations);
  const location = useMemo(() => {
    if (!locations) return;
    return locations.locations.find((i) => i.countryCode === countryCode);
  }, [locations, countryCode]);
  return location;
}

export function useLocation() {
  const {
    i18n: { language },
  } = useTranslation();
  const countryCode = useMemo<string | undefined>(
    () => language?.match(RegExpCountryCode)?.[0],
    [language]
  );
  const locations = useAppSelector((state) => state.static.locations);
  const locationBySettings = useAppSelector((state) => state.settings.location);
  const location = useMemo(() => {
    if (!locations) return;
    return locations.locations.find((i) =>
      locationBySettings
        ? locationBySettings === String(i.id)
        : i.countryCode === countryCode
    );
  }, [locations, countryCode, locationBySettings]);
  return location;
}

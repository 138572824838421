export const LOCALE = "en";
export const STATIC_URL = "https://static.royaletactics.com";
export const LOGO_URL = `${STATIC_URL}/RoyaleTacticsLogo.webp`;

export const GAME_MODES: Record<string, string> = {
  Ranked1v1: "Ranked 1 vs 1 (Old)",
  Ranked1v1_GoldRush: "Ranked 1 vs 1 (Old Gold Rush)",
  PickMode: "Custom",
  CW_Battle_1v1: "Battle 1 vs 1 (Clan War Battle)",
  CW_Duel_1v1: "Duel 1 vs 1 (Clan War Duel)",
  TeamVsTeam: "Team vs Team",
  TripleElixir_Friendly: "Triple Elixir (Friendly)",
  EventDeck_4Card: "Event - 4 Card",
  TeamVsTeam_4Card: "Team vs Team - 4 Card",
  Ranked1v1_NewArena: "Ranked 1 vs 1",
  Ranked1v1_NewArena_GoldRush: "Ranked 1 vs 1 (Gold Rush)",
  Challenge_AllCards_EventDeck: "Event - All-Cards Challenge",
  EventDeck_SKvsSMPekka: "Event - SK vs SM Pekka",
};
export const MATCH_TYPES: Record<string, string> = {
  pathOfLegend: "Path of Legends",
  friendly: "Friendly",
  riverRacePvP: "River Race PvP",
  riverRaceDuel: "River Race Duel",
  riverRaceDuelColosseum: "River Race Colosseum",
  PvP: "Trophy Road",
  seasonalBattle: "Seasonal Battle",
  challenge: "Challenge",
  trail: "Trail",
  boatBattle: "Boat Battle",
  clanMate: "Clan Mate",
  events: "Events",
};

export const SEARCH_PARAMS = { PROFILE_TAB: "profiletab" };
export const VERSION_DEFAULT = "0.0.0";
export const MAX_CLAN_MEMBERS = 50;

// Notification keys
export const NOTIFICATION = {
  SIGNUP: "signUp",
} as const;

export const CLAN_BADGE_DEFAULT = "Traditional_Star_05";
export const INTERNATIONAL_ID = 57000006;

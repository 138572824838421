"use client";

import { TopBar as TopBarPolaris } from "@shopify/polaris";
import { TextField } from "@shopify/polaris";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const SEARCH_DEBOUNCE_DELAY = 400;
const FOCUS_DEBOUNCE_DELAY = 100;
const SEARCH_CHAR_THRESHOLD = 2;
const TIMEOUT = { PREVENT: false } as {
  K: ReturnType<typeof setTimeout> | undefined;
  PREVENT: boolean;
  SEARCH: () => void | undefined;
};
Object.defineProperty(TIMEOUT, "SEARCH", {
  get() {
    TIMEOUT.PREVENT = true;
    if (TIMEOUT.K) clearTimeout(TIMEOUT.K);
    TIMEOUT.K = setTimeout(() => {
      TIMEOUT.PREVENT = false;
    }, FOCUS_DEBOUNCE_DELAY);
    return;
  },
  set(callback: () => void) {
    if (TIMEOUT.PREVENT) return;
    if (TIMEOUT.K) clearTimeout(TIMEOUT.K);
    TIMEOUT.K = setTimeout(callback, FOCUS_DEBOUNCE_DELAY);
  },
});

export default memo(function SearchInput({
  onSearch,
  onFocus,
  onFocusOut,
  onLoad,
  isDark = false,
}: {
  onSearch: (value: string) => void;
  onFocus: (event?: React.FocusEvent) => void;
  onFocusOut?: (event: FocusEvent) => void;
  onLoad?: (rect: DOMRect) => void;
  isDark?: boolean;
}) {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState("");
  const placeholderText = useMemo(
    () => t("searchPlaceholder", "Search player, clan, or character"),
    [t]
  );
  const onSearchDebounce = useCallback(
    _.debounce(onSearch, SEARCH_DEBOUNCE_DELAY),
    [onSearch]
  );
  const handleOnChange = useCallback(
    function _onChange(value: string) {
      setValue(value);
      if (value.length >= SEARCH_CHAR_THRESHOLD) {
        // Start debouncing after character length exceeds threshold
        onSearchDebounce(value);
      } else {
        // Cancel debounce + call on search directly
        onSearchDebounce.cancel();
        onSearch(value);
      }
    },
    [onSearchDebounce]
  );
  const handleOnClear = useCallback(function _onClear() {
    TIMEOUT.SEARCH;
    onSearchDebounce.cancel();
    setValue("");
    onSearch("");
  }, []);
  const handleOnFocus = useCallback(function _onFocus() {
    TIMEOUT.SEARCH = onFocus;
  }, []);
  const handleOnFocusOut = useCallback(function _onFocusOut(
    this: HTMLInputElement,
    event: FocusEvent
  ) {
    if (!onFocusOut) return;
    onSearchDebounce.cancel();
    onFocusOut(event);
  },
  []);
  useEffect(() => {
    const input = ref.current?.getElementsByTagName("input")[0];
    if (!isDark && input) input.style.padding = "1rem 1.25rem";
    if (onLoad && ref.current) onLoad(ref.current.getBoundingClientRect());
    // We have to create our own focusout event because Polaris didn't create one
    // Attach a focusout listener if a callback is specified
    if (onFocusOut && input)
      input.addEventListener("focusout", handleOnFocusOut);
    return () => {
      if (input) input.removeEventListener("focusout", handleOnFocusOut);
    };
  }, []);
  return (
    <div ref={ref}>
      {isDark ? (
        <TopBarPolaris.SearchField
          placeholder={placeholderText}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          value={value}
          onCancel={handleOnClear}
          showFocusBorder={false}
        />
      ) : (
        <TextField
          label="Search"
          labelHidden
          placeholder={placeholderText}
          autoComplete="off"
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          value={value}
          clearButton
          onClearButtonClick={handleOnClear}
        />
      )}
    </div>
  );
});

"use client";

import React, { useEffect, useMemo } from "react";
import {
  BlockStack,
  Box,
  Card,
  Text,
  ResourceList,
  ResourceItem,
  Avatar,
  Scrollable,
} from "@shopify/polaris";
import { useAppSelector } from "@/utils/hooks.ts";
import CardImage from "@/components/CardImage.tsx";
import type { Cards } from "@/models/static.ts";
import { useTranslation } from "react-i18next";
import { refs } from "@/utils/refs.ts";
import { EVENTS } from "@/utils/events";

function handleSearchResultClick() {
  refs.pom(EVENTS.SEARCH_RESULT_CLICK);
}

export default function SearchResults() {
  const {
    i18n: { language },
  } = useTranslation();
  // Loadable states
  const isPlayerFetching = useAppSelector(
    (state) => state.search.isPlayerFetching
  );
  const isClanFetching = useAppSelector((state) => state.search.isClanFetching);
  const isCardsLoading = useAppSelector(
    (state) => state.static.isCardsFetching
  );
  // Static data
  const cards = useAppSelector((state) => state.static.cards);
  // Data states
  const playersSearched = useAppSelector((state) => state.search.players);
  const clansSearched = useAppSelector((state) => state.search.clans);
  const cardsSearched = useAppSelector((state) => state.search.cards);
  // Constants
  const searchStats = useMemo(
    () => ({
      totalItems:
        playersSearched.length + clansSearched.length + cardsSearched.length,
      totalTime: cardsSearched.elapsed ?? 0,
    }),
    [playersSearched, clansSearched, cardsSearched]
  );
  // Effects
  useEffect(() => {
    refs.pom(EVENTS.SEARCH_RESULTS);
  }, []);
  // Render
  return (
    <div>
      <Card roundedAbove="sm" padding="0">
        <Scrollable shadow style={{ height: "600px" }} focusable>
          <div className="p-3">
            <Text as="h2" variant="headingSm">
              Found {searchStats.totalItems.toLocaleString(language)} results in{" "}
              {searchStats.totalTime.toLocaleString(language, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 1,
              })}
              ms
            </Text>
          </div>
          {isPlayerFetching || playersSearched.length ? (
            <Box paddingBlockStart="200">
              <BlockStack gap="200">
                <div className="p-3 py-0">
                  <Text as="h3" variant="headingSm" fontWeight="medium">
                    Players
                  </Text>
                </div>
                <ResourceList
                  loading={isPlayerFetching}
                  resourceName={{ singular: "player", plural: "players" }}
                  items={playersSearched.map((i) => ({
                    id: i.tag,
                    url: `/profile/${i.tag.substring(1, i.tag.length)}`,
                    name: i.name,
                    tag: i.tag,
                  }))}
                  renderItem={(item) => {
                    const { id, url, name, tag } = item;
                    const media = <Avatar customer size="md" name={name} />;
                    return (
                      <ResourceItem
                        id={id}
                        url={url}
                        onClick={handleSearchResultClick}
                        media={media}
                        accessibilityLabel={`View details for ${name}`}
                      >
                        <Text variant="bodyMd" fontWeight="bold" as="h3">
                          {name}
                        </Text>
                        <div>{tag}</div>
                      </ResourceItem>
                    );
                  }}
                />
              </BlockStack>
            </Box>
          ) : null}
          {isClanFetching || clansSearched.length ? (
            <Box paddingBlockStart="200">
              <BlockStack gap="200">
                <div className="p-3 py-0">
                  <Text as="h3" variant="headingSm" fontWeight="medium">
                    Clans
                  </Text>
                </div>
                <ResourceList
                  loading={isClanFetching}
                  resourceName={{ singular: "clan", plural: "clans" }}
                  items={clansSearched.map((i) => ({
                    id: i.name + i.tag,
                    url: `/clan/${i.tag.substring(1, i.tag.length)}`,
                    name: i.name,
                    tag: i.tag,
                  }))}
                  renderItem={(item) => {
                    const { id, url, name, tag } = item;
                    const media = <Avatar customer size="md" name={name} />;
                    return (
                      <ResourceItem
                        id={id}
                        url={url}
                        media={media}
                        onClick={handleSearchResultClick}
                        accessibilityLabel={`View details for ${name}`}
                      >
                        <Text variant="bodyMd" fontWeight="bold" as="h3">
                          {name}
                        </Text>
                        <div>{tag}</div>
                      </ResourceItem>
                    );
                  }}
                />
              </BlockStack>
            </Box>
          ) : null}
          {isCardsLoading || cardsSearched.length ? (
            <Box paddingBlockStart="200">
              <BlockStack gap="200">
                <div className="p-3 py-0">
                  <Text as="h3" variant="headingSm" fontWeight="medium">
                    Characters
                  </Text>
                </div>
                <ResourceList
                  loading={isCardsLoading}
                  resourceName={{ singular: "customer", plural: "customers" }}
                  items={cardsSearched.map((i) => ({
                    id: String(i.id),
                    name: i.name,
                    url: `/cards/${i.id}`,
                  }))}
                  renderItem={({ id, url, name }) => {
                    const card = cards?.[id] as Cards["items"][0];
                    return (
                      <ResourceItem
                        id={id}
                        url={url}
                        onClick={handleSearchResultClick}
                        media={card ? <CardImage card={card} /> : undefined}
                        accessibilityLabel={`View details for ${name}`}
                        verticalAlignment="center"
                      >
                        <Text variant="bodyMd" fontWeight="bold" as="h3">
                          {name}
                        </Text>
                      </ResourceItem>
                    );
                  }}
                />
              </BlockStack>
            </Box>
          ) : null}
        </Scrollable>
      </Card>
    </div>
  );
}

import { IS_PROD, POMPOMPURIN } from "@/constants-app.ts";
import { Queue } from "@/utils/queue.ts";

const EventQueue = Queue();

async function gzipCompressData(data: object): Promise<Uint8Array> {
  const encoder = new TextEncoder();
  const encodedData = encoder.encode(JSON.stringify(data));

  const compressionStream = new CompressionStream("gzip");
  const writer = compressionStream.writable.getWriter();
  writer.write(encodedData);
  writer.close();

  const compressedStream = new Response(compressionStream.readable);
  const compressedArrayBuffer = await compressedStream.arrayBuffer();

  return new Uint8Array(compressedArrayBuffer);
}

async function compressAndSend(eventName: string, data?: Event) {
  try {
    return;
    // if (!IS_PROD) {
    //   console.warn(`Event: ${eventName}`, data);
    //   return;
    // }
    // const payload = { name: eventName };
    // if (data) Object.assign(payload, { data });
    // await fetch(POMPOMPURIN, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Content-Encoding": "gzip",
    //   },
    //   body: await gzipCompressData(payload),
    // });
  } catch {}
}

export type Event = Record<string, string | number>;

export async function trackEvent(
  eventName: string,
  data?: Event
): Promise<void> {
  const base = { pathname: window.location.pathname };
  EventQueue.enqueue(
    compressAndSend(eventName, data ? Object.assign(data, base) : base)
  );
}

const env = process.env.NODE_ENV;
export const IS_PROD = env === "production";
export const API = IS_PROD
  ? "https://be.royaletactics.com"
  : "http://localhost:3005";
export const FETCH_REVALIDATE_HOUR = 3600; // 1 hour
export const FETCH_REVALIDATE_MAX = FETCH_REVALIDATE_HOUR * 24 * 365; // 1 year
export const POMPOMPURIN = "https://pompompurin.royaletactics.com";

// Localstorage keys
export const LS_PLAYER_SEARCH = "localstorageplayersearch";

export const NAVIGATION: Array<{ title: [string, string]; url: string }> = [
  {
    title: ["events", "Events"],
    url: "/events",
  },
  {
    title: ["cards", "Cards"],
    url: "/cards",
  },
  // {
  //   title: ["leaderboards", "Leaderboards"],
  //   url: "/leaderboards",
  // },
  {
    title: ["topClans", "Top Clans"],
    url: "/topclans",
  },
  {
    title: ["topClanWars", "Top Clan Wars"],
    url: "/topclanwars",
  },
];

// Searchparam keys
export const SEARCHPARAMS = {
  ARENA_ID: "arenaId",
  TEAM_SIZE: "teamSize",
  GAME_MODE: "gameMode",
  MATCH_TYPE: "matchType",
};

import { z } from "zod";

const shared = z.array(
  z.object({
    badgeId: z.number(),
    previousRank: z.number(),
    members: z.number(),
    name: z.string(),
    rank: z.number(),
    location: z.object({
      isCountry: z.boolean(),
      countryCode: z.string().optional(),
      name: z.string(),
      id: z.number(),
    }),
    tag: z.string(),
    clanScore: z.number(),
  })
);

export const LeaderboardsClansModel = z.object({
  date: z.number(),
  topClans: shared,
});
export const LeaderboardsClanWarsModel = z.object({
  date: z.number(),
  topClanWars: shared,
});

export type LeaderboardsClans = z.infer<typeof LeaderboardsClansModel>;
export type LeaderboardsClanWars = z.infer<typeof LeaderboardsClanWarsModel>;

import { z } from "zod";

export const QuickSearchPlayerModel = z
  .object({
    lastUpdated: z.number(),
    name: z.string(),
    tag: z.string(),
  })
  .transform(({ lastUpdated, name, tag }) => ({
    name,
    tag,
    last_updated: lastUpdated,
  }));
export const SearchPlayersLSModel = z.record(
  z.string(),
  z.object({
    last_updated: z.number(),
    name: z.string(),
    tag: z.string(),
  })
);
export const SearchPlayersModel = z.array(
  z.object({
    last_updated: z.string().transform((i) => new Date(i).getTime()),
    name: z.string(),
    tag: z.string(),
  })
);

export type QuickSearchPlayer = z.infer<typeof QuickSearchPlayerModel>;
export type SearchPlayerLS = z.infer<typeof SearchPlayersLSModel>;
export type SearchPlayers = z.infer<typeof SearchPlayersModel>;
